.root {
  margin: 10px;
}

.month {
  margin: 0 5px;
}

.day_outside {
  color: var(--color-base-blue-gray600);
}

.caption_label,
.head_row .head_cell {
  color: var(--color-base-blue-gray600);
  font-size: 12px;
  font-weight: 400;
}

.caption_label {
  color: var(--color-base-blue-gray600);
  font-weight: 500;
  font-size: 14px;
}

select + .caption_label {
  display: inline-flex;
  align-items: center;
  padding: 0 0.25em;
}

.caption {
  color: var(--color-base-blue-gray600);
  padding: 14px 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
  border-bottom: 1px solid var(--color-base-blue-gray200);
}

.multiple_months .caption_start .caption {
  flex-direction: row-reverse;
}

.multiple_months .caption {
  justify-content: flex-end;
}

.multiple_months .caption_start .caption_label {
  margin-left: 40px;
}

.multiple_months .caption_end .caption_label {
  margin-right: 40px;
}

.day {
  border-radius: 0;
  font-size: 14px;
}

.day:hover {
  border-radius: 0;
  background-color: var(--color-base-primary-blue10);
}

.nav_button:hover {
  border-radius: 0;
  background-color: transparent;
}

.button {
  width: 34px;
  height: 34px;
  border: 0;
  margin: 0;

  &:hover {
    cursor: pointer;
  }
}

.nav_button_next,
.nav_button_previous {
  width: 24px;
  height: 20px;
}

.nav {
  display: flex;
}

.nav_icon {
  width: 12px;
  height: 12px;

  &:hover {
    cursor: pointer;
  }
}

.button .nav_button {
  height: 10px;
  width: 10px;
}

.day_selected {
  background: var(--color-base-primary-blue700);
  color: var(--color-base-primary-white);
}

.day_range_middle {
  background: var(--color-base-primary-blue700);
}

.day_range_start,
.day_range_end {
  background: var(--color-base-primary-blue400);
}

.day_range_start {
  border-radius: 4px 0 0 4px;
}

.day_range_end {
  border-radius: 0 4px 4px 0;
}

.day_range_start:hover,
.day_range_end:hover {
  background: var(--color-base-primary-blue300);
}

.day_selected:hover {
  background: var(--color-base-primary-blue400);
}

.cell {
  width: 34px;
  height: 34px;
  padding: 0;
}
